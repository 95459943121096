<!--
  - Copyright (C) 2023 NerdSpace, Corp. All Rights Reserved.
  -
  - Authors:
  -  Donam Kim <donam.kim@nerdspace.co.kr>
  -  Jaekyung Oh <jaekyung.oh@nerdspace.co.kr>
  -->

<template>
  <router-view />
</template>

<script setup lang="ts">
</script>
