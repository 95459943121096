/*
 * Copyright (C) 2023 NerdSpace, Corp. All Rights Reserved.
 *
 * Authors:
 *  Donam Kim <donam.kim@nerdspace.co.kr>
 *  Jaekyung Oh <jaekyung.oh@nerdspace.co.kr>
 */

import { createApp } from "vue";
import "@/assets/scss/main.scss";
import "@/assets/scss/partials/font_import.scss";
import router from "@/router";
import App from "@/App.vue";
import { createHead } from "@vueuse/head";
const head = createHead();
const app = createApp(App);

app.use(router);
app.use(head)
app.config.globalProperties.window = window
app.mount("#app");
