/*
 * Copyright (C) 2023 NerdSpace, Corp. All Rights Reserved.
 *
 * Authors:
 *  Donam Kim <donam.kim@nerdspace.co.kr>
 *  Jaekyung Oh <jaekyung.oh@nerdspace.co.kr>
 */

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layouts/TheLayout.vue"),
    children: [
      {
        path: "/",
        component: () => import("@/pages/main/index.vue"),
      },
    ],
  },
  {
    path: "/project/detail",
    component: () => import("@/layouts/TheLayout.vue"),
    children: [
      {
        path: "/project/detail/:id",
        component: () => import("@/pages/project/detail.vue"),
      },
    ]
  },
  {
    path: "/project",
    component: () => import("@/layouts/TheLayout.vue"),
    children: [
      {
        path: "/project",
        component: () => import("@/pages/project/index.vue"),
      },
      {
        path: "/project/:id",
        component: () => import("@/pages/project/detail.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), routes,
});

router.beforeEach(() => {
  // @ts-ignore
  window.scrollTo({top: 0, left: 0, behavior: "instant"});
});

export default router;
